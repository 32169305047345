import { createApp, defineAsyncComponent } from 'vue'

import './assets/fonts/iransans-x/fontiran.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import "toastify-js/src/toastify.css"
import './assets/css/toastr-custom.css'

import './assets/css/app.css'
import './assets/css/themes/default.css'
import './assets/css/popper-theme.css'
import 'swiper/css'
import './registerServiceWorker'

import App from './App.vue'
import launcher from './launcher'


const modules = [
	() => import(/* webpackChunkName: "ai" */ '/src/modules/ai'),
	//() => import(/* webpackChunkName: "nem" */ '/src/modules/nem'),
	//() => import(/* webpackChunkName: "cpay" */ '/src/modules/cpay'),
	//() => import(/* webpackChunkName: "post" */ '/src/modules/post'),
	//() => import(/* webpackChunkName: "guard" */ '/src/modules/guard'),
	//() => import(/* webpackChunkName: "store" */ '/src/modules/store'),
	//() => import(/* webpackChunkName: "netmark" */ '/src/modules/netmark'),
	//() => import(/* webpackChunkName: "sched" */ '/src/modules/sched'),

	//() => import(/* webpackChunkName: "flow" */ '/src/modules/flow'),
	//() => import(/* webpackChunkName: "mail" */ '/src/modules/mail'),
	() => import(/* webpackChunkName: "messenger" */ '/src/modules/messenger'),

	() => import(/* webpackChunkName: "cms" */ '/src/modules/cms'),
	() => import(/* webpackChunkName: "finance" */ '/src/modules/finance'),

	() => import(/* webpackChunkName: "asset" */ '/src/modules/asset'),
	() => import(/* webpackChunkName: "notification" */ '/src/modules/notification'),
	() => import(/* webpackChunkName: "hub" */ '/src/modules/hub'),
]


const app = createApp(App)
app.component('kaveh-form', defineAsyncComponent(() => import(/* webpackChunkName: "com-form" */  './components/form/form')));
app.component('kaveh-table', defineAsyncComponent(() => import(/* webpackChunkName: "com-table" */  './components/table/table')));
app.component('kaveh-list', defineAsyncComponent(() => import(/* webpackChunkName: "com-list" */  './components/list/list')));
app.component('kaveh-popup', defineAsyncComponent(() => import(/* webpackChunkName: "com-popup" */  './components/popup/popup')));
app.component('kaveh-collapse', defineAsyncComponent(() => import(/* webpackChunkName: "com-collapse" */  './components/collapse/collapse')));
app.component('kaveh-button', defineAsyncComponent(() => import(/* webpackChunkName: "com-button" */  './components/button/button')));
app.component('kaveh-excel-import', defineAsyncComponent(() => import(/* webpackChunkName: "com-excel" */  './components/excel/excel-import')));
app.component('kaveh-icon-bootstrap', defineAsyncComponent(() => import(/* webpackChunkName: "com-icon" */  './components/icon/icon-bootstrap')));
app.component('kaveh-task-manager', defineAsyncComponent(() => import(/* webpackChunkName: "com-task" */  './components/task/task-manager')));
app.component('kaveh-content', defineAsyncComponent(() => import(/* webpackChunkName: "com-content" */  './components/content/content')));
app.component('kaveh-ord', defineAsyncComponent(() => import(/* webpackChunkName: "com-ord" */  './components/ord/ord-container')));
app.component('kaveh-ord-node', defineAsyncComponent(() => import(/* webpackChunkName: "com-ord" */  './components/ord/ord-node')));
app.component('kaveh-loading', defineAsyncComponent(() => import(/* webpackChunkName: "com-common" */  './components/common/common-loading')));
app.component('kaveh-confirm-delete', defineAsyncComponent(() => import(/* webpackChunkName: "com-common" */  './components/common/common-confirm-delete')));
app.component('kaveh-overlay', defineAsyncComponent(() => import(/* webpackChunkName: "com-overlay" */  './components/overlay/overlay')));
app.component('kaveh-tree', defineAsyncComponent(() => import(/* webpackChunkName: "com-tree" */  './components/tree/tree-base')));
app.component('kaveh-tree-node', defineAsyncComponent(() => import(/* webpackChunkName: "com-tree" */  './components/tree/tree-node')));
app.component('kaveh-text-editor', defineAsyncComponent(() => import(/* webpackChunkName: "com-text-editor" */  './components/text-editor/text-editor')));
app.component('kaveh-uploader', defineAsyncComponent(() => import(/* webpackChunkName: "com-uploader" */  './components/uploader/uploader-base')));
app.component('kaveh-camera', defineAsyncComponent(() => import(/* webpackChunkName: "com-camera" */  './components/camera/camera')));
app.component('kaveh-code-editor', defineAsyncComponent(() => import(/* webpackChunkName: "com-code-editor" */  './components/code-editor/code-editor')));
app.component('kaveh-signature-pad', defineAsyncComponent(() => import(/* webpackChunkName: "com-signature-pad" */  './components/signature-pad/signature-pad-base')));
app.component('popper', defineAsyncComponent(() => import(/* webpackChunkName: "com" */  'vue3-popper')));
app.component('date-picker', defineAsyncComponent(() => import(/* webpackChunkName: "com" */  'vue3-persian-datetime-picker')));
app.component('vue-barcode', defineAsyncComponent(() => import(/* webpackChunkName: "com" */  '@chenfengyuan/vue-barcode')));




setTimeout(async() =>
{
	await launcher.launch(app, modules)
},0)


